import { container, title } from 'assets/jss/material-kit-react.jsx';

const signupPageStyle = theme => {
  const {
    palette: { type, text, background, common },
    shadows,
  } = theme;
  return {
    card: {
      margin: '0',
      padding: 0,
      width: '100%',
    },
    cardTitle: {
      ...title,
      // fontSize: '1em',
      display: 'inline-block',
      position: 'relative',
      marginTop: '10px',
      minHeight: '32px',
      color: text.primary,
      textDecoration: 'none',
    },
    link: {
      color: text.primary,
    },
    text: {
      flex: 1,
      color: '#999',
    },
    container: {
      ...container,
      zIndex: '2',
      position: 'relative',
      paddingTop: '20vh',
      paddingLeft: 0,
      paddingRight: 0,
      color: '#FFFFFF',
    },
    cardHidden: {
      opacity: '0',
      transform: 'translate3d(0, -60px, 0)',
    },
    pageHeader: {
      height: '100%',
      minHeight: 'calc(100vh - 70px)',
      display: 'flex',
      position: 'relative',
      margin: '0',
      padding: '0',
      border: '0',
      '&:before': {
        background: 'rgba(66, 133, 243, 0.8)',
      },
      '&:before,&:after': {
        position: 'absolute',
        zIndex: '1',
        width: '100%',
        height: '100%',
        display: 'block',
        left: '0',
        top: '0',
        content: '""',
      },
      '& footer li a,& footer li a:hover,& footer li a:active': {
        color: '#FFFFFF',
      },
      '& footer': {
        position: 'absolute',
        bottom: '0',
        width: '100%',
      },
    },
    form: {
      margin: '0',
    },
    cardHeader: {
      boxShadow: shadows[10],
      color: text.primary,
      background: background.default,
      width: 'auto',
      textAlign: 'center',
    },
    socialIcons: {
      maxWidth: '24px',
      marginTop: '0',
      width: '100%',
      transform: 'none',
      left: '0',
      top: '0',
      height: '100%',
      lineHeight: '41px',
      fontSize: '20px',
    },
    divider: {
      height: '1px',
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
      marginTop: '30px',
      marginBottom: '0px',
      textAlign: 'center',
    },
    cardFooter: {
      paddingTop: '0rem',
      border: '0',
      borderRadius: '6px',
      justifyContent: 'center !important',
    },
    socialLine: {
      flex: 1,
      alignSelf: 'center',
      marginTop: '1rem',
      textAlign: 'center',
      padding: '1rem',
    },
    inputIconsColor: {
      color: background.paper,
    },
  };
};

export default signupPageStyle;
