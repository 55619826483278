import React, { useState, useEffect } from 'react';
import { FormattedMessage, navigate } from 'gatsby-plugin-intl';
import * as yup from 'yup';
import { Formik } from 'formik';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
// @material-ui/icons
import CodeIcon from '@material-ui/icons/Code';
import Email from '@material-ui/icons/Email';
import CheckIcon from '@material-ui/icons/Check';
import LockOutlined from '@material-ui/icons/LockOutlined';
// React icons
// core components
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import CardFooter from 'components/Card/CardFooter';
import CustomInput from 'components/CustomInput/CustomInput';
import Button from 'components/CustomButtons';

import {
  forgotPasswordStart,
  forgotPasswordSubmitStart,
} from '@allconnect/store/lib/user/user.actions';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '@allconnect/store/lib/user/user.selectors';
import { selectStatus } from '@allconnect/store/lib/appStatus/appStatus.selectors';
import loginPageStyle from 'assets/jss/material-kit-react/views/loginPage';
import UserActionTypes from '@allconnect/store/lib/user/user.types';

const useStyles = makeStyles(loginPageStyle);

const initValues = {
  email: '',
  password: '',
  password1: '',
  code: '',
};

const formSchema = yup.object().shape({
  email: yup
    .string()
    .email('Invalid email')
    .required('E-mail is required'),
  password: yup.string().min(6, 'Password must have 6 characters'),
  password1: yup.string().min(6, 'Password must have 6 characters'),
});

const ResetPasswordForm = ({
  onCancel,
  appStatus,
  currentUser,
  forgotPassword,
  forgotPasswordSubmit,
}) => {
  const classes = useStyles();
  const [stage, setStage] = useState(0);

  useEffect(() => {
    if (currentUser) {
      navigate('/app/home');
    }
  }, [currentUser]);

  useEffect(() => {
    if (appStatus === UserActionTypes.FORGOTPASSWORD_SUCCESS) {
      setStage(1);
    }
  }, [appStatus]);

  const handleReset = email => {
    forgotPassword(email);
  };
  const handleConfirmSubmit = ({ email, password, code }) => {
    forgotPasswordSubmit({ email, code, password });
  };
  if (currentUser) return null;
  return (
    <Card>
      <Formik
        initialValues={initValues}
        validationSchema={formSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          const { code, email, password } = values;
          if (stage === 0) {
            handleReset(email);
          } else {
            handleConfirmSubmit({ email, password, code });
          }
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form>
            <CardHeader color="success" className={classes.cardHeader}>
              <h1 className={classes.cardTitle}>
                <FormattedMessage
                  id="resetPassword.title"
                  defaultMessage="Reset Password"
                />
              </h1>
              {stage === 0 ? (
                <h3>
                  <FormattedMessage
                    id="resetPassword.enterEmail"
                    defaultMessage="Enter your e-mail to reset password"
                  />
                </h3>
              ) : (
                <h3>
                  <FormattedMessage
                    id="resetPassword.checkEmail"
                    defaultMessage="Check your e-mail and enter the confirmation code"
                  />
                </h3>
              )}
            </CardHeader>
            {stage === 0 ? (
              <>
                <CardBody>
                  <CustomInput
                    labelText={
                      <FormattedMessage
                        id="resetPassword.enterEmail"
                        defaultMessage="Enter your e-mail to reset password"
                      />
                    }
                    error={touched.email && errors.email ? true : false}
                    id="email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: 'email',
                      name: 'email',
                      onChange: handleChange,
                      onBlur: handleBlur,
                      value: values.email,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {errors.email && touched.email && (
                    <FormattedMessage>{errors.email}</FormattedMessage>
                  )}
                </CardBody>
                <CardFooter className={classes.cardFooter}>
                  <Button
                    disabled={isSubmitting}
                    onClick={e => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                    round
                    type="submit"
                    color="google"
                  >
                    <FormattedMessage
                      id="resetPassword.submit"
                      defaultMessage="Submit"
                    />
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    onClick={e => {
                      e.preventDefault();
                      onCancel();
                    }}
                    round
                    type="submit"
                    color="primary"
                  >
                    <FormattedMessage
                      id="resetPassword.cancel"
                      defaultMessage="Cancel"
                    />
                  </Button>
                </CardFooter>
              </>
            ) : (
              <>
                <CardBody>
                  <CustomInput
                    labelText={
                      <FormattedMessage
                        id="resetPassword.confirmCode"
                        defaultMessage="Confirmation code"
                      />
                    }
                    id="confirm code"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    error={errors.code}
                    inputProps={{
                      type: 'text',
                      name: 'code',
                      onChange: handleChange,
                      onBlur: handleBlur,
                      value: values.code,
                      endAdornment: (
                        <InputAdornment position="end">
                          <CodeIcon className={classes.inputIconsColor} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <CustomInput
                    labelText={
                      <FormattedMessage
                        id="resetPassword.newPassword"
                        defaultMessage="New Password"
                      />
                    }
                    id="new password"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: 'password',
                      name: 'password',
                      onChange: handleChange,
                      onBlur: handleBlur,
                      value: values.password,
                      endAdornment: (
                        <InputAdornment position="end">
                          <LockOutlined className={classes.inputIconsColor} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {errors.password && touched.password && errors.password}
                  <CustomInput
                    labelText={
                      <FormattedMessage
                        id="resetPassword.confirmPassword"
                        defaultMessage="Confirm Password"
                      />
                    }
                    id="confirm password"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: 'password',
                      name: 'password1',
                      onChange: handleChange,
                      onBlur: handleBlur,
                      value: values.password1,
                      endAdornment: (
                        <InputAdornment position="end">
                          {values.password !== '' &&
                          values.password === values.password1 ? (
                            <CheckIcon className={classes.inputIconsColor} />
                          ) : (
                            <LockOutlined className={classes.inputIconsColor} />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </CardBody>
                <CardFooter className={classes.cardFooter}>
                  <Button
                    disabled={isSubmitting}
                    onClick={e => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                    round
                    type="submit"
                    color="google"
                  >
                    <FormattedMessage
                      id="resetPassword.submit"
                      defaultMessage="Submit"
                    />
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    onClick={e => {
                      e.preventDefault();
                      onCancel();
                    }}
                    round
                    type="submit"
                    color="primary"
                  >
                    <FormattedMessage
                      id="resetPassword.cancel"
                      defaultMessage="Cancel"
                    />
                  </Button>
                </CardFooter>
              </>
            )}
          </form>
        )}
      </Formik>
    </Card>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  appStatus: selectStatus,
});

const mapDispatchToProps = dispatch => ({
  forgotPassword: email => dispatch(forgotPasswordStart(email)),
  forgotPasswordSubmit: ({ email, code, password }) =>
    dispatch(forgotPasswordSubmitStart({ email, code, password })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm);
