import React, { useState, useEffect } from 'react';
import { FormattedMessage, navigate } from 'gatsby-plugin-intl';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
// @material-ui/icons
import SendIcon from '@material-ui/icons/Send';
import Email from '@material-ui/icons/Email';
import LockOutlined from '@material-ui/icons/LockOutlined';
// React icons
// core components
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import CardFooter from 'components/Card/CardFooter';
import CustomInput from 'components/CustomInput/CustomInput';
import * as yup from 'yup';
import { Formik, ErrorMessage } from 'formik';

import {
  selectAppLoading,
  selectStatus,
} from '@allconnect/store/lib/appStatus/appStatus.selectors';
import {
  emailSignInStart,
  loadUserStart,
} from '@allconnect/store/lib/user/user.actions';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '@allconnect/store/lib/user/user.selectors';
import Button from 'components/CustomButtons';
import loginPageStyle from 'assets/jss/material-kit-react/views/loginPage';
import UserActionTypes from '@allconnect/store/lib/user/user.types';

const useStyles = makeStyles(loginPageStyle);

const initValues = {
  email: '',
  password: '',
};

const formSchema = yup.object().shape({
  email: yup
    .string()
    .email('Invalid email')
    .required('E-mail is required'),
  password: yup.string().required('Password is required'),
});

const LoginPageForm = ({
  setReset,
  appStatus,
  currentUser,
  loginStart,
  loadUser,
}) => {
  const classes = useStyles();
  const [cardAnimation, setAnimation] = useState('cardHidden');

  useEffect(() => {
    let i = false;
    if (!currentUser) {
      setTimeout(function() {
        if (!i) setAnimation('');
      }, 800);
    } else {
      navigate('/app/home');
    }
    return () => (i = true);
  }, [currentUser]);

  useEffect(() => {
    if (appStatus === UserActionTypes.EMAIL_SIGN_IN_SUCCESS) {
      loadUser();
      navigate('/app/home');
    }
  }, [appStatus]);

  const handleLogin = async values => {
    const { email, password } = values;
    await loginStart(email, password);
  };

  return (
    <Card className={classes[cardAnimation]}>
      <Formik
        initialValues={initValues}
        validationSchema={formSchema}
        onSubmit={(values, { setSubmitting }) => {
          if (!currentUser) {
            setSubmitting(true);
            handleLogin(values);
            setSubmitting(false);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form>
            <CardHeader className={classes.cardHeader}>
              <h1 className={classes.cardTitle}>
                <FormattedMessage id="login.title" defaultMessage="Login" />
              </h1>
            </CardHeader>
            <CardBody>
              <CustomInput
                labelText={
                  <FormattedMessage
                    id="login.emailLabel"
                    defaultMessage="Email..."
                  />
                }
                error={touched.email && errors.email ? true : false}
                id="email"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  // autoFocus: true,
                  type: 'email',
                  name: 'email',
                  onChange: handleChange,
                  onBlur: handleBlur,
                  value: values.email,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Email />
                    </InputAdornment>
                  ),
                }}
              />
              <p>
                <ErrorMessage name="email" />
              </p>
              <CustomInput
                labelText={
                  <FormattedMessage
                    id="login.passwordLabel"
                    defaultMessage="Password"
                  />
                }
                id="password"
                error={touched.password && errors.password ? true : false}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: 'password',
                  name: 'password',
                  onChange: handleChange,
                  onBlur: handleBlur,
                  value: values.password,
                  endAdornment: (
                    <InputAdornment position="end">
                      <LockOutlined />
                    </InputAdornment>
                  ),
                }}
              />
              {/* {errors.password && touched.password && errors.password} */}
            </CardBody>

            <CardFooter className={classes.cardFooter}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Button
                  style={{ flex: 1 }}
                  disabled={isSubmitting}
                  onClick={e => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                  round
                  type="submit"
                  color="google"
                  size="lg"
                  endIcon={<SendIcon />}
                >
                  <FormattedMessage
                    id="login.submitButton"
                    defaultMessage="Submit"
                  />
                </Button>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    color="transparent"
                    onClick={() => {
                      setReset(true);
                    }}
                    className={classes.text}
                    style={{ flex: 1 }}
                  >
                    <FormattedMessage
                      id="login.forgotPassword"
                      defaultMessage="Forgot password?"
                    />
                  </Button>
                  <Button
                    color="transparent"
                    onClick={() => {
                      navigate('/register');
                    }}
                    className={classes.text}
                    style={{ flex: 1 }}
                  >
                    <FormattedMessage
                      id="login.register"
                      defaultMessage="Register new Account"
                    />
                  </Button>
                </div>
              </div>
            </CardFooter>
          </form>
        )}
      </Formik>
    </Card>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  appStatus: selectStatus,
});

const mapDispatchToProps = dispatch => ({
  loginStart: (email, password) => dispatch(emailSignInStart(email, password)),
  loadUser: () => dispatch(loadUserStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPageForm);
